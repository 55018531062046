<template>
    
    
   <div class="
    
      border-1 
      rounded-md
      shadow-xl
      
    ">
    <div class="lg:col-span-3 bg-white ">
        <div class="md:p-2 bg-white align-middle">
            <!--  <h1 class="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-green-300 h-auto"> -->
            <h1 class="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-purple-600 " style="font-size: 50px">
                Harvest / Must Pump
            </h1>
        </div>
    </div>
</div>

    <div class="grid grid-cols-3 bg-white"> 
        <div> 
            <img src="https://alphatech-equip.com/static/img/products/harvest-pump.jpg"> 
            </div> 
            <div class="col-span-2 text-left pl-10 mt-10"> 

    <ul> 
        <li> 
Material of construction S.S. 304 </li> <li> 
Variable speed </li> <li> 
Suitable to transfer the crushed fruits along with hopper. </li> <li> 
Hopper consists of screw with separately driven by motor. </li> <li> 
Pump can be used for medium and high-density products. </li> <li> 
Mounting on wheels </li> <li> 
Available in various capacity </li> 

 </ul> 

 </div> 

 </div> 




</template>
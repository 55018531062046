<template> 
<div class="
    
      border-1 
      rounded-md
      shadow-xl
      
    ">
    <div class="lg:col-span-3 bg-white ">
        <div class="md:p-2 bg-white align-middle">
            <!--  <h1 class="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-green-300 h-auto"> -->
            <h1 class="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-purple-600 " style="font-size: 50px">
             Brewery Division!
            </h1>
        </div>
    </div>
</div>
<hr> 


<div class="grid grid-cols-1 md:grid-cols-4 gap-10  p-10 bg-white font-bold text-xl">

            <div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50  "> <a :href="$router.resolve({ name: 'Miller' }).href"> Milling </a> </div>
            <div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"> <a :href="$router.resolve({ name: 'Mushtun' }).href"> Mushtun </a> </div>
             <div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"> <a :href="$router.resolve({ name: 'HltClt' }).href"> HLT CLT  </a> </div>
             <div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"> <a :href="$router.resolve({ name: 'FermenterUnitank' }).href"> Fermenter Uni Tank </a> </div>
             <div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"> <a :href="$router.resolve({ name: 'ElectricBoiler' }).href"> Electric Boiler </a> </div>
             <div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"> <a :href="$router.resolve({ name: 'Ciptrolley' }).href"> CIP Trolley </a> </div>
             <div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"> <a :href="$router.resolve({ name: 'ChillingPlantBrewery' }).href"> Chilling Plant </a> </div>
<div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50" > <a :href="$router.resolve({ name: 'BrewKettle' }).href"> Brew Kettle</a> </div>
<div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"> <a :href="$router.resolve({ name: 'Bbt' }).href"> BBT </a> </div>


</div> 

</template> 

<template>
<Navigation /> 

   <router-view v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view>
  
  <Footer /> 
  

</template>

<script>

import Navigation from './components/Navigation.vue'
import Footer from './components/Footer.vue'
import Home from './components/Home.vue'
export default {
  name: 'App',
  components: {
    Navigation,
    Footer,
    Home
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */ 
  font-family: 'Libre Baskerville',  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}

@import "https://alphatech-equip.com/static/css/tailwind.min.css";
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Libre+Baskerville&family=Montserrat&family=Nunito&family=Oswald:wght@500&family=Roboto&family=Unbounded:wght@400;500&display=swap');


</style>

import { createRouter, createWebHistory } from 'vue-router';
import Contact from '@/components/Contact.vue'
import Home from '@/components/Home.vue'
import About from '@/components/About.vue'
import WineIndex from '@/components/products/WineIndex.vue'
import BreweryIndex from '@/components/products/BreweryIndex.vue'

import AcetatorVinegar from  '@/components/products/wine/AcetatorVinegar.vue'
import Botteling from  '@/components/products/wine/Botteling.vue'
import CartridgeFilter from  '@/components/products/wine/CartridgeFilter.vue'
import ChillingPlant from  '@/components/products/wine/ChillingPlant.vue'
import ColdStabalization from  '@/components/products/wine/ColdStabalization.vue'
import Defilter from  '@/components/products/wine/Defilter.vue'
import Destemmer from  '@/components/products/wine/Destemmer.vue'
import Elevator from  '@/components/products/wine/Elevator.vue'
import FermenterTank from  '@/components/products/wine/FermenterTank.vue'
import FruitCrusher from  '@/components/products/wine/FruitCrusher.vue'
import FruitWasher from  '@/components/products/wine/FruitWasher.vue'
import HarvestPump from  '@/components/products/wine/HarvestPump.vue'
import LabellingMachine from  '@/components/products/wine/LabellingMachine.vue'
import PneumeticPress from  '@/components/products/wine/PneumeticPress.vue'
import ScrewCapping from  '@/components/products/wine/ScrewCapping.vue'
import SortingTable from  '@/components/products/wine/SortingTable.vue'
import TubeHeatExchanger from  '@/components/products/wine/TubeHeatExchanger.vue'
import VibratingTable from  '@/components/products/wine/VibratingTable.vue'

import Bbt from '@/components/products/brewery/Bbt.vue'
import BrewKettle from '@/components/products/brewery/BrewKettle.vue'
import ChillingPlantBrewery from '@/components/products/brewery/ChillingPlantBrewery.vue'
import Ciptrolley from '@/components/products/brewery/Ciptrolley.vue'
import ElectricBoiler from '@/components/products/brewery/ElectricBoiler.vue'
import FermenterUnitank from '@/components/products/brewery/FermenterUnitank.vue'
import HltClt from '@/components/products/brewery/HltClt.vue'
import Miller from '@/components/products/brewery/Miller.vue'
import Mushtun from '@/components/products/brewery/Mushtun.vue'





const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: 'active',
    routes: [
        {path: '/', name: 'Home', component: Home },
        {path: '/contact', name: 'Contact', component: Contact },
        {path: '/products/wine', name: 'WineIndex', component: WineIndex },
        {path: '/about', name: 'About', component: About },
        {path: '/products/acetatorvinegar', name: 'AcetatorVinegar', component: AcetatorVinegar },
        {path: '/products/bottling', name: 'Botteling', component: Botteling },
        {path: '/products/cartridgefilter' , name: 'CartridgeFilter',  component: CartridgeFilter },
        {path:  '/products/chillingplant' , name: 'ChillingPlant',  component: ChillingPlant },
        {path: '/products/coldstabalization' , name: 'ColdStabalization',  component: ColdStabalization },
        {path: '/products/defilter' , name: 'Defilter', component: Defilter },
        {path: '/products/destemmer',  name: 'Destemmer', component: Destemmer },
        {path: '/products/elevator' , name: 'Elevator', component: Elevator },
        {path: '/products/fermentertank' , name: 'FermenterTank', component: FermenterTank },
        {path: '/products/fruitcrusher' , name: 'FruitCrusher', component: FruitCrusher },
        {path: '/products/fruitwasher' , name: 'FruitWasher', component: FruitWasher },
        {path: '/products/harvestpump' , name: 'HarvestPump', component: HarvestPump },
        {path: '/products/labellingmachine' , name: 'LabellingMachine', component: LabellingMachine },
        {path: '/products/pneumeticpress' , name: 'PneumeticPress', component: PneumeticPress },
        {path: '/products/screcapping' , name: 'ScrewCapping', component: ScrewCapping },
        {path: '/products/sortingtable' , name: 'SortingTable', component: SortingTable },
        {path: '/products/tubeheat' , name: 'TubeHeatExchanger', component: TubeHeatExchanger},
        {path: '/products/vibratingtable' , name: 'VibratingTable', component: VibratingTable },
        {path: '/products/brewery', name: 'BreweryIndex', component: BreweryIndex },

        {path: '/products/bbt',  name: 'Bbt', component: Bbt},
        {path: '/products/brewkettle',  name: 'BrewKettle',  component: BrewKettle },
        {path: '/products/chillingplantbrewery',  name: 'ChillingPlantBrewery', component: ChillingPlantBrewery},
        {path: '/products/ciptrolley',  name: 'Ciptrolley', component: Ciptrolley},
        {path: '/products/electricboiler',  name: 'ElectricBoiler', component: ElectricBoiler},
        {path: '/products/fermenterunitank',  name: 'FermenterUnitank', component: FermenterUnitank},
        {path: '/products/hltclt',  name: 'HltClt', component: HltClt},
        {path: '/products/miller', name: 'Miller', component: Miller},
        {path: '/products/mushtun',  name: 'Mushtun', component: Mushtun },


    ],
});
/* 
router.beforeEach(function(to, from,  next) {
    next();
});
*/



export default router;

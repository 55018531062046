<template> 


<br>
<br>
<br>
<br>

<h1 class="font-bold text-center pb-2" style="font-size:50px;" v-if="!enquiry_msg">
                Let us know what you are looking for!
            </h1>

<div class="
grid 
grid-cols-1 
sm:grid-cols-1 
md:grid-cols-3 
lg:grid-cols-3 
gap-2 
sm:w-full 
sm:px-0 "
>
    <div class="col-span-2 bg-white">
        <div class="bg-white flex flex-col flex-1">

            <div class="text-left px-10 md:px-24 pt-10" style="font-size: 14px" v-if="!enquiry_msg">

                <form class="w-full pl-10 max-w-lg" method="POST" @submit.prevent="send_enquiry">
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" :class="{invalid: !emailAddress.isValid}">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-email">
                                Email
                            </label>
                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="text" v-model.trim="emailAddress.val" @blur="clearValidity('emailAddress')" placeholder="xyz@xyz.com" required>
                            <p v-if="!emailAddress.isValid">Email must not be empty or Email Format is Wrong!.</p>
                        </div>
                        <div class="w-full md:w-1/2 px-3" :class="{invalid: !mobileNumber.isValid}">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-phone">
                                Mobile
                            </label>
                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" v-model.trim="mobileNumber.val" @blur="clearValidity('mobileNumber')" placeholder="985000000">
                            <p v-if="!mobileNumber.isValid">Mobile Number must not be empty or must have 10 digits!.</p>
                        </div>
                    </div>
                    <h1 class="mb-4 text-xl text-center font-bold"> Brewery Setup! </h1>
                    <div class="flex flex-wrap -mx-3 mb-2">

                        <div class="flex items-center mr-4 mb-2">
                            <input type="checkbox" value="webapplication" v-model="enquiryvalues" /> &nbsp; &nbsp;
                            <label for="A3-yes" class="select-none"> Looking to setup Brand New Brewery </label>

                        </div>

                    </div>
                    <div class="flex flex-wrap -mx-3 mb-2">

                        <div class="flex items-center mr-4 mb-2">
                            <input type="checkbox" value="digitalmarketing" v-model="enquiryvalues" /> &nbsp; &nbsp;
                            <label for="A3-yes" class="select-none"> Collaboration or Looking for Used Devices </label>
                        </div>

                    </div>

                    <h1 class="mb-4 text-xl text-center font-bold"> Botteling Automation</h1>

                    <div class="flex flex-wrap -mx-3 mb-2">

                        <div class="flex items-center mr-4 mb-2">
                            <input type="checkbox" value="mobileapp" v-model="enquiryvalues" /> &nbsp; &nbsp;
                            <label for="A3-yes" class="select-none"> Renting Out Bottling Machines </label>
                        </div>

                    </div>

                    <div class="flex flex-wrap -mx-3 mb-2">

                        <div class="flex items-center mr-4 mb-2">
                            <input type="checkbox" value="businessapp" v-model="enquiryvalues" /> &nbsp; &nbsp;
                            <label for="A3-yes" class="select-none"> Botteling - New Design and Development </label>
                        </div>

                    </div>

                    <div class="flex flex-wrap -mx-3 mb-2">

                        <div class="flex items-center mr-4 mb-2">
                            <input type="checkbox" value="microsite" v-model="enquiryvalues" /> &nbsp; &nbsp;
                            <label for="A3-yes" class="select-none"> Order Machines </label>
                        </div>

                    </div>

                    <h1 class="mb-4 text-xl text-center font-bold">Industrial Machines </h1>

                    <div class="flex flex-wrap -mx-3 mb-2">

                        <div class="flex items-center mr-4 mb-2">
                            <input type="checkbox" value="restaurant" v-model="enquiryvalues" /> &nbsp; &nbsp;
                            <label for="A3-yes" class="select-none"> Chemical Industry </label>
                        </div>

                    </div>
                    <div class="flex flex-wrap -mx-3 mb-2">

                        <div class="flex items-center mr-4 mb-2">
                            <input type="checkbox" value="smallshop" v-model="enquiryvalues" /> &nbsp; &nbsp;
                            <label for="A3-yes" class="select-none"> Farming </label>
                        </div>

                    </div>
                    <div class="flex flex-wrap -mx-3 mb-2">

                        <div class="flex items-center mr-4 mb-2">
                            <input type="checkbox" value="smes" v-model="enquiryvalues" /> &nbsp; &nbsp;
                            <label for="A3-yes" class="select-none"> Road and Construction</label>
                        </div>

                    </div>

                    <h1 class="mb-4 text-xl text-center font-bold"> Free Form Discussion!</h1>
                    <div class="flex flex-wrap -mx-3 mb-2">

                        <div class="flex items-center mr-4 mb-2">
                            <input type="checkbox" value="freediscussion" v-model="enquiryvalues" /> &nbsp; &nbsp;
                            <label for="A3-yes" class="select-none"> Open for the Discussion! </label>
                        </div>

                    </div>

                    <!--  <div class="flex flex-wrap -mx-3 mb-2">
                        Help us Reduce Spam - Friendly Captcha!
                    </div>
                    -->

                    <div class="text-left px-10 pt-10" style="font-size: 14px">
                        <button type="submit" value="submit" class="
            py-2
            hover:underline
            bg-indigo-600
            text-white
            font-bold
            rounded-full
            w-40
            shadow-lg
            focus:outline-none
            focus:shadow-outline
          ">
                            Submit Enquiry
                        </button>
                    </div>
                </form>

            </div>

            <div v-else>
                <h1 class="font-bold text-center pb-2" style="font-size:28px;">
                    Thank you for writing to us!
                </h1>

            </div>

        </div>

    </div>
    <div class="bg-white object-contain ml-0 p-10 md:mt-20 md:pr-10 md:-ml-52">
        <img src="https://alphatech-equip.com/static/img/contact/contact_us.jpg">
    </div>
</div>

<br>
<br>

<br>
<br>




</template> 

<script> 


export default {
    name: 'Contact',
   
     data() {
        return {
            enquiryvalues: [],
            email: '',
            emailAddress: {
                val: '',
                isValid: true,
            },
            mobileNumber: {
                val: '',
                isValid: true
            },
            formIsValid: true,
            mobile: '',
        }
    },

}

</script> 

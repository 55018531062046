<template>
<div class="
    
      border-1 
      rounded-md
      shadow-xl
      
    ">
    <div class="lg:col-span-3 bg-white ">
        <div class="md:p-2 bg-white align-middle">
            <!--  <h1 class="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-green-300 h-auto"> -->
            <h1 class="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-purple-600 " style="font-size: 50px">
                Wine Division!
            </h1>
        </div>
    </div>
</div>
<div class="my-auto mx-2 md:mx-10 text-sm md:text-xl bg-white">
    <nav>
        <ul class="Tabs rounded-lg">

            <li :class="{ new_active: active === 'one' }" class="Tabs__tab Tab" @click="active_tab('one')"><a href="#">All Products</a></li>
            <li :class="{ new_active: active === 'two' }" class="Tabs__tab Tab" @click="active_tab('two')"><a href="#">Tables</a></li>
            <li :class="{ new_active: active === 'three' }" class="Tabs__tab Tab" @click="active_tab('three')"><a href="#">Bottling Products</a></li>
            <li :class="{ new_active: active === 'four' }" class="Tabs__tab Tab" @click="active_tab('four')"><a href="#">Farm Related Products</a></li>
            <li class="Tabs__presentation-slider" role="presentation"></li>
        </ul>
    </nav>
</div>

<div class="container mx-auto p-4 bg-white">

    <div v-if="active === 'one'">

        <div class="grid grid-cols-1 md:grid-cols-4 gap-10  p-10 bg-white font-bold text-xl">

            <div class="grid grid-cols-2 gap-0 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"> 
               <div class="pl-10 md:pl-0">  <img src="https://alphatech-equip.com/static/img/products/fruit-washer.jpg" class="h-20">  </div>
               <div class="-ml-24 md:-ml-0 mt-4">  <a :href="$router.resolve({ name: 'FruitWasher' }).href">  Fruit Washer! </a> </div>
            </div>
            <div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">  <a :href="$router.resolve({ name: 'AcetatorVinegar' }).href"> Acetator Vinegar </a> </div>
            <div class="grid grid-cols-2 gap-0 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"> 
                <div class="pl-10 md:pl-0">  <img src="https://alphatech-equip.com/static/img/products/botteling.png" class="h-20">  </div>
                <div class="-ml-24 md:-ml-0 mt-4"> <a :href="$router.resolve({ name: 'Botteling' }).href"> Botteling </a>  </div>
                
            </div>
            <div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">  <a :href="$router.resolve({ name: 'CartridgeFilter' }).href"> CartridgeFilter</a> </div>
            <div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">  <a :href="$router.resolve({ name: 'ChillingPlant' }).href"> Chilling Plant </a> </div>
            <div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">  <a :href="$router.resolve({ name: 'ColdStabalization' }).href"> Cold Stabalization</a> </div>
            <div class="grid grid-cols-2 gap-0 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">  
                <div class="pl-10 md:pl-0">  <img src="https://alphatech-equip.com/static/img/products/de_filter.jpg" class="h-20">  </div>
                <div class="-ml-24 md:-ml-0 mt-4"><a :href="$router.resolve({ name: 'Defilter' }).href"> DE - Filter </a>  </div>

            </div>

            <div class="grid grid-cols-2 gap-0  shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"> 
                <div class="pl-10 md:pl-0">  <img src="https://alphatech-equip.com/static/img/products/destemmer_crusher.jpg" class="h-20">  </div>
                <div class="-ml-24 md:-ml-0 mt-4"><a :href="$router.resolve({ name: 'Destemmer' }).href"> Destemmer </a> </div>

            
                </div>

            <div class="grid grid-cols-2 gap-0 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">  
                <div class="pl-10 md:pl-0 "> <img src="https://alphatech-equip.com/static/img/products/elevator.jpg" class="h-20">  </div>
                <div class="-ml-24 md:-ml-0 mt-4">  <a :href="$router.resolve({ name: 'Elevator' }).href"> Elevetor </a>  </div> 
            
            </div>
            <div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">  <a :href="$router.resolve({ name: 'FermenterTank' }).href"> Fermenter Tank </a> </div>
            <div class="grid grid-cols-2 gap-0 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">
                <div class="pl-10 md:pl-0 "> <img src="https://alphatech-equip.com/static/img/products/fruit_crusher.jpg" class="h-20">  </div>
                <div class="-ml-24 md:-ml-0 mt-4"><a :href="$router.resolve({ name: 'FruitCrusher' }).href"> Fruit Crusher</a> </div>
             
                
                </div>
            <div class="grid grid-cols-2 gap-0 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"> 
                <div class="pl-10 md:pl-0 "> <img src="https://alphatech-equip.com/static/img/products/harvest-pump.jpg" class="h-20">  </div> 
                <div class="-ml-24 md:-ml-0 mt-4"><a :href="$router.resolve({ name: 'HarvestPump' }).href"> Harvest Pump </a> </div>
                </div>
            <div class="grid grid-cols-2 gap-0 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">  
                <div class="pl-10 md:pl-0 "> <img src="https://alphatech-equip.com/static/img/products/labeling_machine.jpg" class="h-20">  </div>
                <div class="-ml-24 md:-ml-0 mt-4">  <a :href="$router.resolve({ name: 'LabellingMachine' }).href"> Labbeling Machine </a>  </div> 
            
            </div>
            <div class="grid grid-cols-2 gap-0 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"> 
                <div class="pl-10 md:pl-0 "> <img src="https://alphatech-equip.com/static/img/products/pneumaticPress.jpg" class="h-20">  </div>
                <div class="-ml-24 md:-ml-0 mt-4">   <a :href="$router.resolve({ name: 'PneumeticPress' }).href"> Pneumatic Press</a> </div> 

            </div>
            <div class="h-10  pt-2 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">  <a :href="$router.resolve({ name: 'ScrewCapping' }).href"> Screw Capping </a> </div>
            <div class="grid grid-cols-2 gap-0 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"> 
                <div class="pl-10 md:pl-0 "> <img src="https://alphatech-equip.com/static/img/products/sorting_table.png" class="h-20">  </div>
                <div class="-ml-24 md:-ml-0 mt-4"> <a :href="$router.resolve({ name: 'SortingTable' }).href"> Sorting Table </a> </div> 
            </div>
            <div class="grid grid-cols-2 gap-0 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">  
                <div class="pl-10 md:pl-0 "> <img src="https://alphatech-equip.com/static/img/products/tube_and_tube.jpg" class="h-20">  </div>  
                <div class="-ml-24 md:-ml-0 mt-4"><a :href="$router.resolve({ name: 'TubeHeatExchanger'}).href"> Tube In Tube Heat Exchanger </a> </div> 

            </div>
            <div class="grid grid-cols-2 gap-0 shadow-lg rounded-lg hover:bg-blue-500  hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">  
                <div class="pl-8 md:pl-0"> <img src="https://alphatech-equip.com/static/img/products/vibrating_sorting_table.jpg" class="h-20">  </div>
                <div class="-ml-24 md:-ml-0 mt-4"><a :href="$router.resolve({ name: 'VibratingTable' }).href"> Vibrating Table </a> </div>  
            </div>

        </div>
    </div>

</div>
</template>

<script>
 export default {

     name: 'WineIndex',

     data() {
         return {
             active: 'one',
         }
     },
     methods: {

         active_tab(text) {
             this.active = text;
         }
     }

 }
</script>

<style scoped>
.Tabs {
    position: relative;
    background-color: #fff;

}

.Tabs:after {
    content: ' ';
    display: table;
    clear: both;
}

.Tabs {
    margin: 0;
    padding: 0;
    list-style: none;
}

.Tabs__tab {
    float: left;
    width: 25%;
    text-align: center;
}

.Tabs__tab:first-child.new_active~.Tabs__presentation-slider {
    transform: translateX(0) scaleX(0.25);
}

.Tabs__tab:nth-child(2).new_active~.Tabs__presentation-slider {
    transform: translateX(25%) scaleX(0.25);
}

.Tabs__tab:nth-child(3).new_active~.Tabs__presentation-slider {
    transform: translateX(calc(25% * 2)) scaleX(0.25);
}

.Tabs__tab:nth-child(4).new_active~.Tabs__presentation-slider {
    transform: translateX(calc(25% * 3)) scaleX(0.25);
}

.Tabs__presentation-slider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #4A66F4;
    transform-origin: 0 0;
    transition: transform .25s;
}

.Tab {
    font-family: 'Roboto Slab';
}

.Tab>a {
    display: block;
    padding: 10px 12px;
    text-decoration: none;
    color: #666;
    transition: color .15s;
}

.Tab.new_active>a {
    color: #222;
}

.Tab:hover>a {
    color: #222;
}
</style>

<template>
<nav class="main-nav relative bg-white  border-gray-300 text-gray-900 sticky top-0 z-50 mb-10" :class="{ 'onScroll': !view.topOfPage}">
    <div class="container mx-auto flex justify-between items-center ">
        <div class="flex pt-2">
            <div class="relative block  p-1 lg:p-6 text-sm text-blue-600 font-bold">

                <a :href="$router.resolve({ name: 'Home' }).href">
                    <img src="https://alphatech-equip.com/static/img/alphtech-logo.png" class="h-10 md:h-16 px-10 md:px-0 object-cover">
                </a>
            </div>
            <div class=" relative block p-1 text-sm text-blue-600 font-bold">
                <a :href="$router.resolve({ name: 'Home' }).href">

                </a>
            </div>
        </div>

        <ul class="flex nav-links">

            <!--Hoverable Link-->
            <li class="hoverable hover:bg-blue-800 hover:text-white">
                <a href="#" class=" block lg:p-6 text-sm lg:text-base font-bold hover:bg-blue-800 hover:text-white">Products</a>
                <div class="mega-menu  sm:mb-0 shadow-xl bg-blue-800">
                    <div class="container" >
                        <div class="">
                            <ul class="">
                                <div class=" flex justify-center">
                                  <a :href="$router.resolve({ name: 'WineIndex' }).href">
                                    <h3 class="font-bold text-xl text-white text-bold mb-2">Wine Division  &nbsp; </h3>
                                    </a> 
                                </div>
                            </ul>
                        </div>
                        <div class="">

                            <ul class="lg:pt-3 ">
                                <div class=" flex justify-center"> 
<a :href="$router.resolve({ name: 'BreweryIndex' }).href" > 
                                    <h3 class="font-bold text-xl text-white text-bold mb-2">Brewery Division &nbsp; &nbsp;</h3>
                                    </a> 
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>

            <!--Hoverable Link-->
            <!-- 
            <li class="hoverable hover:bg-blue-800 hover:text-white">
                <a href="#" class="relative block py-6 px-4 lg:p-6 text-sm lg:text-base font-bold hover:bg-blue-800 hover:text-white">Projects Offered</a>
                <div class="p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-blue-800">
                    <div class="container mx-auto w-full flex flex-wrap justify-between mx-2">
                        <div class="w-full text-white mb-8">
                            <h2 class="font-bold text-2xl">We help accelarate your project</h2>
                            <p>We offer our tangible knowledge to work on your projects</p>
                        </div>
                        <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 border-b sm:border-r lg:border-b-0 pb-6 pt-6 lg:pt-3">
                            <div class="flex items-center">
                                <svg class="h-8 mb-3 mr-3 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M3 6c0-1.1.9-2 2-2h8l4-4h2v16h-2l-4-4H5a2 2 0 0 1-2-2H1V6h2zm8 9v5H8l-1.67-5H5v-2h8v2h-2z" />
                                </svg>

                                <h3 class="font-bold text-xl text-white text-bold mb-2">Winery Setup!</h3>
                            </div>
                            <p class="text-gray-100 text-sm">Quarterly sales are at an all-time low create spaces to explore the accountable talk and blind vampires.</p>
                            <div class="flex items-center py-3">
                                <svg class="h-6 pr-3 fill-current text-blue-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z" />
                                </svg>
                                <a href="#" class="text-white bold border-b-2 border-blue-300 hover:text-blue-300">Find out more...</a>
                            </div>
                        </ul>
                        <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 border-b sm:border-r-0 lg:border-r lg:border-b-0 pb-6 pt-6 lg:pt-3">
                            <div class="flex items-center">
                                <svg class="h-8 mb-3 mr-3 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M4.13 12H4a2 2 0 1 0 1.8 1.11L7.86 10a2.03 2.03 0 0 0 .65-.07l1.55 1.55a2 2 0 1 0 3.72-.37L15.87 8H16a2 2 0 1 0-1.8-1.11L12.14 10a2.03 2.03 0 0 0-.65.07L9.93 8.52a2 2 0 1 0-3.72.37L4.13 12zM0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4z" />
                                </svg>
                                <h3 class="font-bold text-xl text-white text-bold mb-2">Brew That Fruit</h3>
                            </div>
                            <p class="text-gray-100 text-sm">Prioritize these line items game-plan draw a line in the sand come up with something buzzworthy UX upstream selling.</p>
                            <div class="flex items-center py-3">
                                <svg class="h-6 pr-3 fill-current text-blue-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z" />
                                </svg>
                                <a href="#" class="text-white bold border-b-2 border-blue-300 hover:text-blue-300">Find out more...</a>
                            </div>
                        </ul>
                        <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 border-b sm:border-b-0 sm:border-r md:border-b-0 pb-6 pt-6 lg:pt-3">
                            <div class="flex items-center">
                                <svg class="h-8 mb-3 mr-3 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M2 4v14h14v-6l2-2v10H0V2h10L8 4H2zm10.3-.3l4 4L8 16H4v-4l8.3-8.3zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
                                </svg>
                                <h3 class="font-bold text-xl text-white text-bold mb-2">Handle Storage</h3>
                            </div>
                            <p class="text-gray-100 text-sm">This proposal is a win-win situation which will cause a stellar paradigm shift, let's touch base off-line before we fire the new ux experience.</p>
                            <div class="flex items-center py-3">
                                <svg class="h-6 pr-3 fill-current text-blue-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z" />
                                </svg>
                                <a href="#" class="text-white bold border-b-2 border-blue-300 hover:text-blue-300">Find out more...</a>
                            </div>
                        </ul>
                        <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 pb-6 pt-6 lg:pt-3">
                            <div class="flex items-center">
                                <svg class="h-8 mb-3 mr-3 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
                                </svg>
                                <h3 class="font-bold text-xl text-white text-bold mb-2">Farming Easy</h3>
                            </div>
                            <p class="text-gray-100 text-sm">This is a no-brainer to wash your face, or we need to future-proof this high performance keywords granularity.</p>
                            <div class="flex items-center py-3">
                                <svg class="h-6 pr-3 fill-current text-blue-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z" />
                                </svg>
                                <a href="#" class="text-white bold border-b-2 border-blue-300 hover:text-blue-300">Find out more...</a>
                            </div>
                        </ul>
                    </div>
                </div>
            </li>
--> 
            <!--Regular Link-->
            <li class="hover:bg-blue-800 hover:text-white">
                <a :href="$router.resolve({ name: 'Contact' }).href" class="relative block py-6 px-2 lg:p-6 text-sm lg:text-base font-bold">Contact Us!</a>
            </li>

            <!--Regular Link-->
            <li class="hover:bg-blue-800 hover:text-white">
                <a :href="$router.resolve({ name: 'About' }).href" class="relative block py-6 px-2 lg:p-6 text-sm lg:text-base font-bold">About Us</a>
            </li>

            <!--Toggleable Link-->
            <li class="toggleable hover:bg-blue-800 hover:text-white">
                <input type="checkbox" value="selected" id="toggle-one" class="toggle-input">
                <label for="toggle-one" class="block cursor-pointer py-6 px-4 lg:p-6 text-sm lg:text-base font-bold">News</label>
                <!-- 
                <div role="toggle" class="p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-blue-800">
                
                    <div class="container mx-auto w-full flex flex-wrap justify-between mx-2">
                        <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 border-b sm:border-r lg:border-b-0 pb-6 pt-6 lg:pt-3">
                            <h3 class="font-bold text-xl text-white text-bold mb-2">Heading 1</h3>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                        </ul>
                        <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 border-b sm:border-r-0 lg:border-r lg:border-b-0 pb-6 pt-6 lg:pt-3">
                            <h3 class="font-bold text-xl text-white text-bold mb-2">Heading 2</h3>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                        </ul>
                        <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 border-b sm:border-b-0 sm:border-r md:border-b-0 pb-6 pt-6 lg:pt-3">
                            <h3 class="font-bold text-xl text-white text-bold">Heading 3</h3>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                            <li>
                                <a href="#" class="block p-3 hover:bg-blue-600 text-gray-300 hover:text-white">Category One Sublink</a>
                            </li>
                        </ul>
                        <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 pb-6 pt-6 lg:pt-3">
                            <h3 class="font-bold text-xl text-white text-bold mb-2">Heading 4</h3>
                            <li class="pt-3">
                                <img src="https://placehold.it/205x172">
                            </li>
                        </ul>
                    </div>

                
                </div>
            --> 
            </li>

        </ul>
        <div @click="toggle_menu" class="burger">
            <div class="line line1"></div>
            <div class="line line2"></div>
            <div class="line line3"></div>
        </div>
    </div>
    <div class="bg-blue-800 text-white text-xl text-left font-fold border-b-2  mx-20 rounded px-10 py-5 mb-10 lg:hidden" :class="{'hidden': mobile_menu }">
        <ul>
            <li class="border-b cursor-pointer"> Products </li>
            <li class="border-b cursor-pointer mt-1"> Projects Offered </li>
            <li class="border-b cursor-pointer mt-1"> <a :href="$router.resolve({ name: 'Contact' }).href"> Contact Us </a> </li>
            <li class="border-b cursor-pointer mt-1"> <a :href="$router.resolve({ name: 'About' }).href"> About Us </a> </li>
            <li class="mt-1 cursor-pointer"> News </li>
        </ul>
    </div>

</nav>
</template>

<script>
export default {
    name: 'SiteNavigation',
    data() {
        return {
            view: {
                topOfPage: true
            },
            mobile_menu: true,
        }
    },

    props: {

    },

    methods: {
        toggle_menu() {
            
            this.mobile_menu = !this.mobile_menu
        },

        handleScroll() {

            if (window.pageYOffset > 0) {
                if (this.view.topOfPage) this.view.topOfPage = false
            } else {
                if (!this.view.topOfPage) this.view.topOfPage = true
            }
        },

    },
    beforeMount() {
        window.addEventListener('scroll', this.handleScroll)
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->


<style scoped>
BODY {
    background: #FAFAFA;
}

/* #Mega Menu Styles
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.mega-menu {
    display: none;
    position: absolute;
    text-align: left;
    width: 20%;
    left: auto;
}

/* #hoverable Class Styles
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.hoverable {
    position: static;
}

.hoverable>a:after {
    content: "\25BC";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
}

.hoverable:hover .mega-menu {
    display: block;

}

/* #toggle Class Styles
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.toggleable>label:after {
    content: "\25BC";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
}

.toggle-input {
    display: none;
}

.toggle-input:not(checked)~.mega-menu {
    display: none;
}

.toggle-input:checked~.mega-menu {
    display: block;
}

.toggle-input:checked+label {
    color: white;
    background: #2c5282;
    /*@apply bg-blue-800 */
}

.toggle-input:checked~label:after {
    content: "\25B2";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
}

.burger {
    display: none;
    padding: 0.5rem;
    cursor: pointer;
}

.line {
    height: 2px;
    width: 35px;
    margin: 0.5rem 0;
    background: #ca6d02;
    transition: transform 0.75s ease, opacity 0.75s ease;
}

.main-nav.show .line1 {
    transform: translate(-10px) rotate(45deg) translate(15px);
}

.main-nav.show .line2 {
    opacity: 0;
}

.main-nav.show .line3 {
    transform: translate(-10px) rotate(-45deg) translate(15px);
}

.main-nav.show .nav-links {
    transform: translateX(0%);
}

@media screen and (max-width: 800px) {
    .nav-links {
        width: 60%;
    }
}

@media screen and (max-width: 1024px) {
    .burger {
        display: block;
    }

    .nav-links {
        font-size: 12px;
        position: fixed;
        top: 8vh;
        right: 0;
        height: 92vh;
        width: 50%;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background: #005582;
        transform: translateX(100%);
        transition: transform 1s ease;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
    }
}

.main-nav.onScroll {

    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@import "https://fonts.googleapis.com/css?family=Great Vibes";

.alphatechheader {
    font-family: 'Great Vibes';
}
</style>

<template>
    
    
   <div class="
    
      border-1 
      rounded-md
      shadow-xl
      
    ">
    <div class="lg:col-span-3 bg-white ">
        <div class="md:p-2 bg-white align-middle">
            <!--  <h1 class="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-green-300 h-auto"> -->
            <h1 class="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-purple-600 " style="font-size: 50px">
               Labelling Machine

            </h1>
        </div>
    </div>
</div>

    <div class="grid grid-cols-3 bg-white"> 
        <div> 
            <img src="https://alphatech-equip.com/static/img/products/labeling_machine.jpg"> 
            </div> 
            <div class="col-span-2 text-left pl-10 mt-10"> 

    <ul> 
        <li> 
            MOC S.S. 304  </li> <li> 
Advanced HMI system PLC based. </li> <li> 
Suitable for glass bottles. </li> <li> 
Low maintenance. </li> <li> 
Direction – left to right. </li> <li> 
Machine dimension: - 1900(l)x 1000 (w)x 1400 (h) </li> 


 </ul> 

 </div> 

 </div> 




</template>
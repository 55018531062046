<template>
    
    
   <div class="
    
      border-1 
      rounded-md
      shadow-xl
      
    ">
    <div class="lg:col-span-3 bg-white ">
        <div class="md:p-2 bg-white align-middle">
            <!--  <h1 class="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-green-300 h-auto"> -->
            <h1 class="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-purple-600 " style="font-size: 50px">
               Fermenters (Tank)





            </h1>
        </div>
    </div>
</div>

    <div class="grid grid-cols-3 bg-white"> 
        <div> 
            <img src="https://alphatech-equip.com/static/img/products/xxxx"> 
            </div> 
            <div class="col-span-2 text-left pl-10 mt-10"> 

    <ul> 
        <li> 
            Various capacity </li> <li> 
MOC- S.S. 304 / S.S. 316                </li> <li>                            
Internal surface: 2B/BA finish: cleaned and brushed welding seams	 </li> <li> 
External surface: Circle polished </li> <li> 
Top hatch (Cylindrical, Hinged) </li> <li> 
Manhole- Rectangular / Elliptical </li> <li> 
Racking valve	 </li> <li> 
Bottom drain point  </li> <li> 
Thermometer probe </li> <li> 
Taper leg/pipe leg </li> <li> 
Level gauge valve        </li> <li>      
Sample cock           </li> <li>                   
CIP </li> <li> 
Screen for flat bottom tanks </li> <li> 
Pressure vacuum relief valve </li> <li> 
Insulation as per requirement </li> 








 </ul> 

 </div> 

 </div> 




</template>

<template>


<div class="
      grid 
      grid-cols-1
      sm:grid-cols-1
      md:grid-cols-1
      lg:grid-cols-3
      border-1 
      rounded-md
      shadow-xl
      
    ">
    <div class="lg:col-span-3 bg-white ">
        <div class="md:p-2 bg-white align-middle">
            <!--  <h1 class="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-green-300 h-auto"> -->
            <h1 class="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-purple-600 " style="font-size: 50px">
                Industry Leader in Machines!
            </h1>
        </div>
    </div>

    <div class="lg:col-span-2 bg-white ">
        <div class="md:p-2 bg-white align-middle">

            <div class="
            text-left text-xl
            leading-normal
            transform
            transition
            duration-500
            hover:scale-100
            px-5 md:px-24 lg:px-40
            mt-3
          " style="font-size: 18px">
                The world is fast moving with Information Technlogy innvovations supported by Industrial Revolution.
                The Alphatech Process Equipments are into the business for more than a decade now
                <br>
                We help our parners accross multiple domains with <span class="font-bold" style="color: #4f47e5"> Industrial Equimentments</span>, setting up infrastructure.
                <br>
                <br>
                <br>
                You focus on your business - while we help you on:

            </div>

            <div class="
            text-left text-xl
            leading-normal
            transform
            transition
            duration-500
            hover:scale-100
            px-5 md:px-24 lg:px-40
          " style="font-size: 16px;">

                <div class="uvp-list">
                    <ul>
                        <li> &nbsp; Design and Planning your Infrastructure</li>
                        <li>&nbsp;Design and Build your Industrial Equiments </li>
                    </ul>
                </div>

            </div>

            <div class="
            text-center
            px-16
            leading-normal
            transform
            transition
            duration-500
            hover:scale-110
          " style="font-size: 24px">

                <a :href="$router.resolve({ name: 'Contact' }).href">

                    <button class="
              mx-auto
              lg:mx-0
              hover:underline
              bg-indigo-600
              text-white
              font-bold
              rounded-full
              my-6
              py-4
              px-8
              shadow-lg
              focus:outline-none
              focus:shadow-outline
              transform
              transition
              hover:scale-105
              duration-300
              ease-in-out
              w-25
              text-center
            ">
                        Send Enquiry
                    </button>
                </a>
            </div>
        </div>
    </div>

    <div class="bg-white">
        <img src="https://alphatech-equip.com/static/img/beer-fermentation-tank-brewing-plant.jpg" class="mb-10 -ml-10" />
    </div>

</div>

<div class="
      grid 
      grid-cols-1
      sm:grid-cols-1
      md:grid-cols-1
      lg:grid-cols-3
      border-1 
      rounded-md
      shadow-xl
      
    ">

    <div class="lg:col-span-3">
        <Carousel :autoplay="5000" :wrap-around="true">
            <Slide v-for="prod in alphatech_products" :key="prod">
              <div class="p-10 md:p-32"> 
                <img :src="prod.pic" class="shadow-lg" style="border-radius:5%; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">  
             </div> 
            </Slide>
            <template #addons>
                <Pagination />
            </template>
        </Carousel>
    </div>
</div>

<br>

<div class="
      grid 
      grid-cols-1
      sm:grid-cols-1
      md:grid-cols-1
      lg:grid-cols-3
      border-1 
      rounded-md
      shadow-xl
      
    ">
    <div class="lg:col-span-3 bg-white ">
        <div class="md:p-2 bg-white align-middle">
            <!--  <h1 class="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-green-300 h-auto"> -->
            <h1 class="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-purple-600 " style="font-size: 50px">
                Our Esteemed Customers!
            </h1>
        </div>
    </div>

</div>

<div class="pt-10 px-10 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 gap-8 bg-white">

    <div class="bg-white shadow-lg rounded-lg border-b-2 border-red-800" v-for="partner in partners" :key="partner">

<article class="overflow-hidden rounded-lg">

                <a href="#">
                    <img :alt="partner.name" class="block h-auto w-full" :src="partner.pic">
                </a>

               
                <footer class="flex items-center justify-between leading-none p-2 md:p-4">
                    <a class="flex items-center no-underline hover:underline text-black" href="#">
                        
                        <p class="ml-2 text-sm">
                            {{ partner.name }}
                        </p>
                    </a>
                    
                </footer>

            </article>

    </div>



</div>


</template>

<script>



import {
    Carousel,

    Pagination,
    Slide,

} from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default {

    name: 'Home',
    components: {
    
        
        Carousel,
        Slide,
        Pagination

    },
    data() {
        return {
            alphatech_products: [{
                    name: 'One',
                    info: 'One',
                    pic: 'https://alphatech-equip.com/static/img/slider/one_slide.jpg'
                },
                {
                    name: 'Two ',
                    info: 'Two',
                    pic: 'https://alphatech-equip.com/static/img/slider/two_slide.jpg'
                },
                {
                    name: 'Three',
                    info: 'Three',
                    pic: 'https://alphatech-equip.com/static/img/slider/three_slide.jpg'
                },
                {
                    name: 'Four',
                    info: 'Four',
                    pic: 'https://alphatech-equip.com/static/img/slider/four_slide.jpg'
                },
                {
                    name: 'Five',
                    info: 'Five',
                    pic: 'https://alphatech-equip.com/static/img/slider/five_slide.jpg'
                },
                {
                    name: 'Six',
                    info: 'Six',
                    pic: 'https://alphatech-equip.com/static/img/slider/six_slide.jpg'
                },
                {
                    name: 'Seven',
                    info: 'Seven',
                    pic: 'https://alphatech-equip.com/static/img/slider/seven_slide.jpg'
                },
                {
                    name: 'Eight',
                    info: 'Eight',
                    pic: 'https://alphatech-equip.com/static/img/slider/eight_slide.jpg'
                },

            ],

            partners: [
                 {
                    name: 'Royal Big Master',
                    pic: 'https://alphatech-equip.com/static/img/partners/royal_big_master.jpg'
                },

                {
                    name: 'Aditya Agro',
                    pic: 'https://alphatech-equip.com/static/img/partners/aditya_agro_industries.jpg'
                },
                {
                    name: 'Bagalkot Univ',
                    pic: 'https://alphatech-equip.com/static/img/partners/bagalkot_university.jpg'
                },
                {
                    name: 'Divine Wine',
                    pic: 'https://alphatech-equip.com/static/img/partners/divine_wine.png'
                },
                {
                    name: 'Elite Vintage',
                    pic: 'https://alphatech-equip.com/static/img/partners/elite-vintage-winery-profile.jpg'
                },
                {
                    nane: 'Four Seasons',
                    pic: 'https://alphatech-equip.com/static/img/partners/four_seasons.png'
                },
                {
                    name: 'Good Drop',
                    pic: 'https://alphatech-equip.com/static/img/partners/good_drop.jpg'
                },
                {
                    name: ' Grover Zampa Wines ',
                    pic: 'https://alphatech-equip.com/static/img/partners/grover_zampa_wines.jpg'
                },
                {
                    name: 'Hinwa Wine',
                    pic: 'https://alphatech-equip.com/static/img/partners/hinwa_wine.png'
                },
                {
                    name: 'Indospirit ',
                    pic: 'https://alphatech-equip.com/static/img/partners/indospirit.jpg'
                },
                {
                    name: 'Meher',
                    pic: 'https://alphatech-equip.com/static/img/partners/meher-logo.png'
                },
                {
                    name: 'Moet',
                    pic: 'https://alphatech-equip.com/static/img/partners/moet.png'
                },
                {
                    name: ' Naara Aaba Lambu Subu ',
                    pic: 'https://alphatech-equip.com/static/img/partners/naara_aaba_lambu_subu.jpg'
                },
                {
                    name: 'Norphel Winery',
                    pic: 'https://alphatech-equip.com/static/img/partners/norphel_winery.png'
                },
                {
                    name: 'Pernod Ricard',
                    pic: 'https://alphatech-equip.com/static/img/partners/pernod_ricard.png'
                },
                {
                    name: 'Pioneer',
                    pic: 'https://alphatech-equip.com/static/img/partners/pioneer.jpg'
                },
                {
                    name: 'Sankata',
                    pic: 'https://alphatech-equip.com/static/img/partners/sankata.png'
                },
                {
                    name: 'SDU',
                    pic: 'https://alphatech-equip.com/static/img/partners/sdu.jpg'
                },
                {
                    name: 'Seven Peaks',
                    pic: 'https://alphatech-equip.com/static/img/partners/seven_peaks.jpg'
                },
                {
                    name: 'Snowman Winery',
                    pic: 'https://alphatech-equip.com/static/img/partners/snowman_winery.jpg'
                },
                {
                    name: 'Soma',

                    pic: 'https://alphatech-equip.com/static/img/partners/soma.png'
                },
                {
                    name: 'South Seas Distilleries',
                    pic: 'https://alphatech-equip.com/static/img/partners/south_seas_distilleries.png'
                },
                
                {
                    name: 'Sula Vineyards',
                    pic: 'https://alphatech-equip.com/static/img/partners/sula.png'
                },
                {
                    name: 'United Spirits Ltd',
                    pic: 'https://alphatech-equip.com/static/img/partners/united-spirits-ltd.png'
                },
                {
                    name: 'York Winery',
                    pic: 'https://alphatech-equip.com/static/img/partners/york_winery.jpg'
                }
            ],
        
    }
  },

}
</script>

<style>
.uvp-list ul {
    display: inline-block;
    padding-left: 1.5rem;
    max-width: 320px;
}

.uvp-list ul {
    list-style: none;
}

.uvp-list ul>li {
    clear: left;
    padding: .2rem 0;
}

.uvp-list ul>li:before {
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    display: block;
    float: left;
    margin-left: -1.5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.uvp-list ul>li:before {
    /*  background: url("/static/img/checkmark-round-orange.svg"); */
    background: url("https://alphatech-equip.com/static/img/checkmark-round-blue.png");
    background-size: cover;
    background-position: center;
    padding: .15rem;
}

.carousel__item {
    min-height: 200px;
    width: 100%;
    background-color: var(--carousel-color-primary);
    color: var(--carousel-color-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel__slide {
    padding: 10px;
}

.carousel__prev,
.carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
}
</style>

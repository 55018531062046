<template>
    
    
   <div class="
    
      border-1 
      rounded-md
      shadow-xl
      
    ">
    <div class="lg:col-span-3 bg-white ">
        <div class="md:p-2 bg-white align-middle">
            <!--  <h1 class="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-green-300 h-auto"> -->
            <h1 class="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-purple-600 " style="font-size: 50px">
             Screw Capping Machine


            </h1>
        </div>
    </div>
</div>

    <div class="grid grid-cols-3 bg-white"> 
        <div> 
            <img src="https://alphatech-equip.com/static/img/products/screw_capping_machine.jpg"> 
            </div> 
            <div class="col-span-2 text-left pl-10 mt-10"> 

    <ul> 
        <li> 
            Wine bottle sealing with redraw on top. This is very important to avoid oxidation. Without cap insertion chute. </li> <li> 
Capacity: - 1400 to 1500 BPH </li> <li> 
Manual loading and unloading </li> <li> 
Cap size: - 30 x 60 cap. </li> <li> 
MOC: - SS 304. </li> <li> 
Minimum change over time. </li> <li> 
Low noise level & low power consumption. </li> <li> 
Suitable for 360 ml, 750 ml bottle. </li> 




 </ul> 

 </div> 

 </div> 




</template>
<template>



<div > 

<h1 class="text-3xl font-extrabold"> About Us </h1>
<hr> 

</div> 

<div> 
    <p class="px-2 md:px-24 pt-5 md:pt-20  text-sm md:text-xl text-left"> 
        The company was founded in 2001 to help setup Wine Making Infrastructure. The company is responsible for setting up almost 90% of wineries from the country. We have helped big brands like Sula, York, UB Group etc. in India as well as Hima, Royal etc in neighbouring country Nepal. 
</p>
<p class="px-2 md:px-24 pt-5 md:pt-10 text-sm md:text-xl text-left">
We get involved in the start to end process of Wine making - we do help our partners to identify the fruits, identify the business potential in the local and international market.    We help our customers with ideas to take the product to market. 

</p> 
</div> 



<div class="relative" style="padding-top: 56.25%">
  <iframe class="absolute inset-0 w-full p-5 md:p-24 h-full" src="https://www.youtube.com/embed/VsV2DovT650" frameborder="0"></iframe>
</div>



</template> 

<script> 



export default {
    name: 'About',
    components: {

}

}
</script> 

<template>
    
    
   <div class="
    
      border-1 
      rounded-md
      shadow-xl
      
    ">
    <div class="lg:col-span-3 bg-white ">
        <div class="md:p-2 bg-white align-middle">
            <!--  <h1 class="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-green-300 h-auto"> -->
            <h1 class="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-purple-600 " style="font-size: 50px">
               Brew Kettle 
            </h1>
        </div>
    </div>
</div>

    <div class="grid grid-cols-3 bg-white"> 
        <div> 
            <img src="https://alphatech-equip.com/static/img/products/xxxx.png"> 
            </div> 
            <div class="col-span-2 text-left pl-10 mt-10"> 

    <ul> 
        <li> 

            Various capacity </li> <li> 
MOC- S.S. 304 / S.S. 316         </li> <li>                                
Internal surface:   BA finish cleaned and brushed welding seams	</li> <li> 
External surface:  2b/matt</li> <li> 
Tangential inlet </li> <li> 
Top hatch (cylindrical, hinged):</li> <li> 
Heating jacket </li> <li> 
CIP</li> <li> 
Bottom drain point</li> <li> 
Thermometer probe</li> <li> 
Pipe leg</li> <li> 
Insulation
</li> 

 </ul> 

 </div> 

 </div> 




</template>
<template>
    
    
   <div class="
    
      border-1 
      rounded-md
      shadow-xl
      
    ">
    <div class="lg:col-span-3 bg-white ">
        <div class="md:p-2 bg-white align-middle">
            <!--  <h1 class="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-green-300 h-auto"> -->
            <h1 class="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-purple-600 " style="font-size: 50px">
              Pneumatic Press

            </h1>
        </div>
    </div>
</div>

    <div class="grid grid-cols-3 bg-white"> 
        <div> 
            <img src="https://alphatech-equip.com/static/img/products/pneumaticPress.jpg"> 
            </div> 
            <div class="col-span-2 text-left pl-10 mt-10"> 

    <ul> 
        <li> 
           Available in various capacity </li> <li> 
AISI 304 stainless steel closed cage </li> <li> 
Stainless steel frame </li> <li> 
Two SS manual doors  </li> <li> 
Polyurethane food grade membrane </li> <li> 
Control panel with push buttons </li> <li> 
Power: 3 phase 50 hz.  </li> <li> 
Mounted on wheel </li> 



 </ul> 

 </div> 

 </div> 




</template>